import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';

interface ModalProps {
    title: string;
    description: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
    onClose: () => void;
    isAlert?: boolean;
}

const ConfirmationModal: React.FC<ModalProps> = ({
    title,
    description,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onClose,
    isAlert = false,
}) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <button className={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <h2 className={`${styles.title} ${isAlert ? styles.alertTitle : ''}`}>
                    {title}
                </h2>
                <p className={styles.description}>{description}</p>
                <div className={styles.actions}>
                    <button className={styles.secondaryButton} onClick={onSecondaryButtonClick}>
                        {secondaryButtonText}
                    </button>
                    <button 
                        className={`${styles.primaryButton} ${isAlert ? styles.alertPrimaryButton : ''}`}
                        onClick={onPrimaryButtonClick}
                    >
                        {primaryButtonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
