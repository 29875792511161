const RedDeleteIcon = (props) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.34616 13.6671C1.97394 13.6671 1.65644 13.5359 1.39366 13.2737C1.13144 13.0115 1.00033 12.694 1.00033 12.3212V2.00039H0.166992V1.16706H3.50033V0.525391H8.50033V1.16706H11.8337V2.00039H11.0003V12.3212C11.0003 12.7046 10.872 13.0246 10.6153 13.2812C10.3581 13.5384 10.0378 13.6671 9.65449 13.6671H2.34616ZM10.167 2.00039H1.83366V12.3212C1.83366 12.4707 1.88171 12.5934 1.97783 12.6896C2.07394 12.7857 2.19671 12.8337 2.34616 12.8337H9.65449C9.78227 12.8337 9.89977 12.7804 10.007 12.6737C10.1137 12.5665 10.167 12.449 10.167 12.3212V2.00039ZM4.17366 11.1671H5.00699V3.66706H4.17366V11.1671ZM6.99366 11.1671H7.82699V3.66706H6.99366V11.1671Z"
      fill="#D95656"
    />
  </svg>
);

export default RedDeleteIcon;